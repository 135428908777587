
@import url('https://fonts.googleapis.com/css2?family=Inter');

.App {
  text-align: center;
  font-family: "Inter" !important;
  color: white;

}

* {
  box-sizing: border-box;
}

body {
  font-family: "Inter" !important;
  overflow: auto;
  position: fixed;
  background-color: black;

}


.overlay {
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    /*background-color: black;*/


}
.roadmap {
    opacity: 1;
    width: 100%;
    /*height: 20%;*/
    overflow: hidden;

    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: black;
}
.about {
    opacity: 1;
    width: 100%;
    /*height: 20%;*/
    overflow: hidden;

    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #0A0A0A;
}

.moving-background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0A0A0A;
  background-image: url("./assets/background/bg.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-blend-mode: revert;
  overflow: hidden;
}

.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
}

.expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.expired-notice > p {
    font-size: 1.5rem;
}

.show-counter {
    padding: 0.5rem;
}

.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: Bold;
    font-size: 40px;
    line-height: 1.75rem;
    /*border: 1px solid #ebebeb;*/
    font-family: "Inter";
    text-decoration: none;
    color: #7CFC00;
    padding: 0.0rem 0.0rem 0px;

}

.show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown > p {
    margin: 0;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }

.project-coming ul {
    list-style-image: url('../src/assets/images/icon-li.png');
}
li p {
    display:inline-block;
    vertical-align:middle;
 }

 .footer {
    /* position: fixed; */
    left: 0;
    bottom: 0;
    width: 100%;
     height: 100px;
     /*background-color: #0A0A0A;*/
    color: white;
    text-align: center;
    margin-top: 1px;
 }
 .btn-circle {
  width: 300px;
  border-radius: 50% !important;
  border: 0px solid #DCB839;
  color: #DCB839;
  font-size: 32px;
  background: transparent !important;
 }

.flashing {
    animation: flashingText 1s linear infinite;
}

@keyframes flashingText {
    0% {
        color: transparent;
    }
    50% {
        color: #FFFFFF;
    }
    100% {
        color: #FFFFFF;

    }
}



.btn-circle > img {
    max-width: 230px;
 }

 .carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 115% !important;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0px;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
    margin-left: 38%;
    margin-right: 38%;
 }

 @media screen and (max-width: 414px) {
    .carousel-control-next, .carousel-control-prev {

        margin-left: 30%;
        margin-right: 30%;
     }
 }

 .css-g2cols {
    font-size: 10px;
    margin-top: -30px;
}
.btn-opensea span  {
    color: #DCB839;
}
.btn-opensea:hover span  {
    color: #FFF;
}

.hightlight-border {
    border: 'solid 2px #DCB839' !important;
}